// import libraries
import React from "react";
import { Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
// import css
import "../assets/styles/searchBar.scss";

/*
SearchBar component
@params :
  - firstParams: {type} - description

Component description
*/

export default function SearchBar({ placeholder, value, setValue }) {
  return (
    <div className='searchBar'>
      <Form.Control
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className='searchBar__input'
      />
      <FaSearch className='searchBar__icon' />
    </div>
  );
}
