// import libraries
import React from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

/*
Add battery component
@params :
    - battery {object} => new battery park to add.
    - deleteItem {function} => delete this battery of array.

All fields needed to create a new battery.
*/

export default function AddBattery({ battery, deleteItem }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addBattery.title')}
        </span>
        <Button
          variant="danger"
          onClick={() => deleteItem(battery.id)}
          className="canDelete__button"
        >
          <FaRegTrashAlt />
        </Button>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addBattery.quantity')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addBattery.quantity')}
            onChange={(e) => (battery.quantity = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={4}>
            {t('components.addBattery.assignment')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addBattery.assignment')}
            onChange={(e) => (battery.assignment = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addBattery.type')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addBattery.type')}
            onChange={(e) => (battery.type = e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addBattery.voltage')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addBattery.voltage')}
              onChange={(e) => (battery.voltage = e.target.value)}
            />
            <InputGroup.Text>V</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addBattery.amperage')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addBattery.amperage')}
              onChange={(e) => (battery.amperage = e.target.value)}
            />
            <InputGroup.Text>A</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="update__label"></Form.Group>
      </Row>
    </React.Fragment>
  );
}
