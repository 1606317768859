// import libraries
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import utils
import { postDataUser, putDataUser } from '../utils/api';
import { API_ROUTES, LOCAL_URL } from '../utils/constants';
// import css
import '../assets/styles/buttonModalAdmin.scss';

/**
 * ButtonModalDocuments component
 *
 * Ce composant React affiche un bouton qui, lorsqu'il est cliqué, ouvre une fenêtre modale permettant de créer ou de mettre à jour un document.
 * L'utilisateur peut remplir les champs "name" et "description" et sélectionner un fichier à télécharger.
 * Ce fichier peut ensuite être soumis à une API sous la forme de données multipart/form-data.
 *
 * @component
 *
 * @param {Object} props - Les propriétés passées au composant.
 * @param {React.ReactNode} props.iconButton - (Optionnel) Icône à afficher à côté du texte du bouton.
 * @param {string} props.nameButton - Le texte à afficher sur le bouton.
 * @param {string} props.variantButton - Le style du bouton (par exemple : "success", "danger").
 * @param {Object} [props.data] - (Optionnel) Les données existantes si l'utilisateur met à jour un document.
 *                                 Utilisées pour pré-remplir les champs du formulaire.
 * @param {string} [props.size] - (Optionnel) La taille du bouton (par exemple : "sm" pour petit, "lg" pour large).
 * @param {string} [props.className] - (Optionnel) Classe CSS personnalisée pour le bouton.
 *
 * @returns {React.ReactElement} Composant ButtonModalDocuments
 *
 * @example
 * <ButtonModalDocuments
 *   iconButton={<i className="fas fa-plus"></i>}
 *   nameButton="Ajouter Document"
 *   variantButton="primary"
 *   data={null}
 *   size="lg"
 *   className="my-custom-class"
 * />
 *
 */

const initialState = {
  id: '',
  name: '',
  description: '',
  file: null,
};

// Reducer pour gérer l'état du formulaire
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.value };
    case 'UPDATE':
      return { ...state, [action.field]: action.value };
    case 'SET_FILE':
      return { ...state, file: action.file };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export default function ButtonModalDocuments({
  iconButton,
  nameButton,
  variantButton,
  data,
  updateData,
  setUpdateData,
  size,
  className,
}) {
  const [show, setShow] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (data && show) {
      dispatch({ type: 'SET_STATE', value: data });
      dispatch({ type: 'UPDATE', field: 'file', value: data.file_path });
    }
    // eslint-disable-next-line
  }, [show]);

  const handleChange = (field) => (e) => {
    dispatch({ type: 'UPDATE', field, value: e.target.value });
  };

  // Gérer le changement du fichier uploadé
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Récupérer le fichier téléchargé
    dispatch({ type: 'SET_FILE', file }); // Mettre à jour le state avec le fichier
  };

  // Soumission du formulaire pour créer ou mettre à jour un document
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Création d'un objet FormData pour l'upload multipart
    const formData = new FormData();
    formData.append('name', state.name);
    formData.append('description', state.description);
    if (state.file) {
      formData.append('file', state.file);
    }
    if (data) {
      formData.append('id', state.id);
    }

    let response;
    if (data) {
      response = await putDataUser(API_ROUTES.DOCUMENTS.PUT.TEMPLATE, formData);
    } else {
      response = await postDataUser(
        API_ROUTES.DOCUMENTS.POST.TEMPLATE,
        formData,
      );
    }
    if (response.status === 201 || response.status === 200) {
      handleClose();
      dispatch({ type: 'RESET' });
      setUpdateData(!updateData);
    }
  };

  // Réinitialisation du formulaire et fermeture du modal
  const handleReset = () => {
    handleClose();
    dispatch({ type: 'RESET' });
  };

  return (
    <React.Fragment>
      {/* Bouton qui ouvre le modal */}
      <Button
        variant={variantButton}
        size={size}
        onClick={handleShow}
        className={className}
      >
        {iconButton ? (
          <React.Fragment>
            {iconButton}
            <span>{nameButton}</span>
          </React.Fragment>
        ) : (
          nameButton
        )}
      </Button>

      {/* Modal qui contient le formulaire */}
      <Modal show={show} onHide={handleReset}>
        <Modal.Header closeButton>
          <Modal.Title>
            {data
              ? `${t('components.buttonModalDocuments.update')} ${data.name}`
              : `${t('components.buttonModalDocuments.create')}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="form__adding__entity"
            onSubmit={(e) => handleSubmit(e)}
            onReset={handleReset}
            className="modal__body__form"
          >
            <Form.Group className="modal__body__form__group">
              <Form.Control
                type="text"
                placeholder={t('components.buttonModalDocuments.name')}
                value={state.name}
                onChange={handleChange('name')}
              />
              <Form.Control
                type="text"
                placeholder={t('components.buttonModalDocuments.description')}
                value={state.description}
                onChange={handleChange('description')}
              />
            </Form.Group>
            <Form.Group>
              {data && data.file_path && (
                <p>
                  {t('components.buttonModalDocuments.currentFile')}
                  <a
                    href={`${LOCAL_URL}/${data.file_path.replace('src/', '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.file_path.split('/').pop()}
                  </a>
                </p>
              )}
              <Form.Control
                type="file"
                accept=".docx"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleReset}>
            {t('components.buttonModalDocuments.cancel')}
          </Button>
          <Button
            variant="success"
            type="submit"
            form="form__adding__entity"
            onClick={handleSubmit}
          >
            {t('components.buttonModalDocuments.validate')}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
