// import libraries
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
// import components
import TransactionCore from "../../components/transactions/transactionCore";
import TransactionCommercial from "../../components/transactions/transactionCommercial";
// import utils
import {
  deleteDataID,
  getData,
  getDataID,
  postDataID,
  putDataID,
} from "../../utils/api";
import { API_ROUTES } from "../../utils/constants";
// import css
import "../../assets/styles/transaction.scss";

/*
Transaction page
Display all details of a specific transaction by id
*/

export default function Transaction() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isSumbiting, setIsSubmiting] = useState(false);
  const [data, setData] = useState();
  const [dataUpdate, setDataUpdate] = useState();
  const [commissionUpdate, setCommissionUpdate] = useState([]);
  const [commissionTotal, setCommissionTotal] = useState();
  const [commissionDisplay, setCommissionDisplay] = useState(0);
  const [sharesUpdate, setSharesUpdate] = useState([]);
  const [show, setShow] = useState(false);
  const [bgToast, setBGToast] = useState("danger");
  const [textToast, setTextToast] = useState("Message");
  const [addBuyer, setAddBuyer] = useState([]);
  const [newFetch, setNewFetch] = useState(false);
  // features
  const featureTransactionNetwork = "transaction_network";
  const featureTransactionAgency = "transaction_agency";
  const featureTransactionUpdate = "transaction_update";
  const [auth, setAuth] = useState([]);
  const [isDisplay, setIsDisplay] = useState(false);

  useEffect(() => {
    const fetchAuthoriz = async () => {
      try {
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
      } catch (error) {
        console.log("error on fetch auth ", error);
      }
    };
    fetchAuthoriz();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (auth.length > 0) {
      const fetchData = async () => {
        try {
          const response = await getDataID(
            API_ROUTES.TRANSACTIONS.GET.WITHID,
            id
          );
          setData(response.data);
          setDataUpdate(response.data);
          setIsDisplay(
            auth.includes(featureTransactionNetwork) ||
              auth.includes(featureTransactionAgency) ||
              response.data.id_user ===
                JSON.parse(localStorage.getItem("ids")).user
          );
          if (
            !auth.includes(featureTransactionNetwork) &&
            !auth.includes(featureTransactionAgency)
          ) {
            response.data.id_user !==
              JSON.parse(localStorage.getItem("ids")).user && navigate(-1);
          }
        } catch (error) {
          if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            navigate("/login"); // redirect to login page if error 403
          } else if (
            error?.response?.status === 401 &&
            error?.response?.data.message === "Redirect to Transactions"
          ) {
            navigate(-1); // redirect to transactions page
          } else {
            console.log("people index L120 error ", error);
          }
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [navigate, auth, id]);

  const commissionCalcul = (id, value) => {
    setCommissionTotal((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (commissionTotal) {
      setCommissionDisplay(
        Object.values(commissionTotal).reduce((sum, val) => sum + val, 0)
      );
    }
  }, [commissionTotal]);

  const addNewBuyer = () => {
    const newBuyer = {
      id: addBuyer.length + 1,
      id_people: null,
      id_transaction: id,
      shares: null,
      status_people: 1,
    };
    setAddBuyer([...addBuyer, newBuyer]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmiting(true);
    try {
      if (JSON.stringify(data) !== JSON.stringify(dataUpdate)) {
        await putDataID(API_ROUTES.TRANSACTIONS.PUT.UPDATE, dataUpdate, id);
        setData(dataUpdate);
      }
      if (commissionUpdate.length > 0) {
        await putDataID(API_ROUTES.PEOPLE.PUT.BOATS, commissionUpdate);
      }
      if (addBuyer.length > 0) {
        await postDataID(API_ROUTES.TRANSACTIONS.POST.BUYER, addBuyer, id);
      }
      if (sharesUpdate.length > 0) {
        await putDataID(API_ROUTES.TRANSACTIONS.PUT.SHARES, sharesUpdate);
      }

      setNewFetch(!newFetch);
      setAddBuyer([]);
      setBGToast("success");
      setTextToast("Successfully saved!");
      setShow(true);
    } catch (error) {
      console.log("error transaction index submit L77: ", error);
      setBGToast("danger");
      setTextToast("ERROR: Failed saved.");
      setShow(true);
    } finally {
      setIsSubmiting(false);
    }
  };

  const handleReset = () => {
    setDataUpdate(data);
    setAddBuyer([]);
  };

  const handleDelete = async () => {
    setIsSubmiting(true);
    try {
      const params = new URLSearchParams();
      params.append("boat", data.id_boat);
      const response = await deleteDataID(
        API_ROUTES.TRANSACTIONS.DELETE.TRANSACTION,
        id,
        params
      );
      if (response.status === 200) {
        setBGToast("success");
        setTextToast("Successfully deleted!");
        setShow(true);
        navigate(-1);
      }
    } catch (error) {
      console.log("error transaction index submit L103: ", error);
      setBGToast("danger");
      setTextToast("ERROR: Failed deleted.");
      setShow(true);
    } finally {
      setIsSubmiting(false);
    }
  };

  return (
    <div className='transaction'>
      {isLoading ? (
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
        ></Spinner>
      ) : (
        <React.Fragment>
          {isDisplay && data && (
            <TransactionCore
              isLoading={isSumbiting}
              id={id}
              date={data?.creation_date}
              price={dataUpdate?.price_offer}
              status={dataUpdate?.status}
              prepayment={dataUpdate?.prepayment}
              priceOffer={dataUpdate?.price_offer}
              id_boat={data?.id_boat}
              setDataUpdate={setDataUpdate}
              commissionUpdate={commissionUpdate}
              setCommissionUpdate={setCommissionUpdate}
              commissionCalcul={commissionCalcul}
              sharesUpdate={sharesUpdate}
              setSharesUpdate={setSharesUpdate}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              show={show}
              setShow={setShow}
              bgToast={bgToast}
              textToast={textToast}
              handleDelete={handleDelete}
              addBuyer={addBuyer}
              setAddBuyer={setAddBuyer}
              addNewBuyer={addNewBuyer}
              newFetch={newFetch}
              rightUpdate={auth.includes(featureTransactionUpdate)}
            />
          )}
          <div className='transaction__rightSide'>
            {isDisplay && data && (
              <TransactionCommercial
                isLoading={isSumbiting}
                user={data?.id_user}
                startCompromise={dataUpdate?.start_date_compromise_creation}
                expireDate={dataUpdate?.compromise_expiry_date}
                priceOffer={dataUpdate?.price_offer}
                commission={commissionDisplay}
                id_boat={data?.id_boat}
                setDataUpdate={setDataUpdate}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                show={show}
                setShow={setShow}
                bgToast={bgToast}
                textToast={textToast}
                rightUpdate={auth.includes(featureTransactionUpdate)}
              />
            )}
            <div className='secondBloc'>Files manager WIP</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
