// import libraries
import React from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { IoDuplicate } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

/*
Add engine component
@params :
    - transmission {array of objects} => all transmissions possible.
    - engine {object} => new engine to add.
    - deleteItem {function} => delete this engine of array.

All fields needed to create a new engine.
*/

export default function AddEngine({
  transmission,
  engine,
  deleteItem,
  duplicateItem,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addEngine.title')}
        </span>
        <div className="btn__container">
          <Button
            variant="warning"
            onClick={() => duplicateItem(engine.id)}
            className="canDelete__button"
          >
            <IoDuplicate />
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteItem(engine.id)}
            className="canDelete__button"
          >
            <FaRegTrashAlt />
          </Button>
        </div>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addEngine.brand')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addEngine.brand')}
            defaultValue={engine.brand}
            onChange={(e) => (engine.brand = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={4}>
            {t('components.addEngine.model')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addEngine.model')}
            defaultValue={engine.model}
            onChange={(e) => (engine.model = e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addEngine.power')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addEngine.power')}
              defaultValue={engine.power}
              onChange={(e) => (engine.power = e.target.value)}
            />
            <InputGroup.Text>CV</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={4}>
            {t('components.addEngine.fiscalPower')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addEngine.fiscalPower')}
              defaultValue={engine.fiscal_power}
              onChange={(e) => (engine.fiscal_power = e.target.value)}
            />
            <InputGroup.Text>CV</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addEngine.hours')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addEngine.hours')}
              defaultValue={engine.hours}
              onChange={(e) => (engine.hours = e.target.value)}
            />
            <InputGroup.Text>h</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={4}>
            {t('components.addEngine.transmission')}
          </Form.Label>
          <Form.Select
            defaultValue={engine.id_transmission}
            onChange={(e) => (engine.transmission = e.target.value)}
          >
            {transmission.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addEngine.serialNumber')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addEngine.serialNumber')}
            defaultValue={engine.serial_number}
            onChange={(e) => (engine.serial_number = e.target.value)}
          />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
