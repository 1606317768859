// import libraries
import React from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

/*
Add generator component
@params :
    - generator {object} => new generator to add.
    - deleteItem {function} => delete this generator of array.

All fields needed to create a new generator.
*/

export default function AddGenerator({ generator, deleteItem }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addGenerator.title')}
        </span>
        <Button
          variant="danger"
          onClick={() => deleteItem(generator.id)}
          className="canDelete__button"
        >
          <FaRegTrashAlt />
        </Button>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addGenerator.brand')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addGenerator.brand')}
            onChange={(e) => (generator.brand = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={4}>
            {t('components.addGenerator.model')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addGenerator.model')}
            onChange={(e) => (generator.model = e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addGenerator.power')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addGenerator.power')}
              onChange={(e) => (generator.power = e.target.value)}
            />
            <InputGroup.Text>kW</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={4}>
            {t('components.addGenerator.hours')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addGenerator.hours')}
              onChange={(e) => (generator.hours = e.target.value)}
            />
            <InputGroup.Text>h</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addGenerator.serialNumber')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addGenerator.serialNumber')}
            onChange={(e) => (generator.serial_number = e.target.value)}
          />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
