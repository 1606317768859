// import libraries
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

/*
Add cabin component
@params :
    - cabinsTypes {array of objects} => all types of cabins possible.
    - position {array of objects} => all positions possible.
    - cabin {object} => new cabin to add.
    - deleteItem {function} => delete this cabin of array.

All fields needed to create a new cabin.
*/

export default function AddCabin({ cabinsTypes, position, cabin, deleteItem }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addCabin.title')}
        </span>
        <Button
          variant="danger"
          onClick={() => deleteItem(cabin.id)}
          className="canDelete__button"
        >
          <FaRegTrashAlt />
        </Button>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addCabin.type')}
          </Form.Label>
          <Form.Select onChange={(e) => (cabin.id_cabin_type = e.target.value)}>
            {cabinsTypes.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addCabin.position')}
          </Form.Label>
          <Form.Select onChange={(e) => (cabin.id_position = e.target.value)}>
            {position.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={8}>
            {t('components.addCabin.quantitySingleBed')}
          </Form.Label>
          <Form.Control
            placeholder="Quantity"
            onChange={(e) => (cabin.quantity_single_bed = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Check
            type="checkbox"
            label={t('components.addCabin.airConditioning')}
            onChange={(e) => (cabin.air_conditioning = e.target.checked)}
            className="container__checkbox"
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Check
            type="checkbox"
            label={t('components.addCabin.pulseAirHeating')}
            onChange={(e) => (cabin.pulse_air_heating = e.target.checked)}
            className="container__checkbox"
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md={2} className="update__label">
          <Form.Check
            type="checkbox"
            label={t('components.addCabin.doubleBed')}
            onChange={(e) => (cabin.double_bed = e.target.checked)}
            className="container__checkbox"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            as="textarea"
            rows={1}
            onChange={(e) => (cabin.double_bed_description = e.target.value)}
            className="container__description"
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md={2} className="update__label">
          <Form.Check
            type="checkbox"
            label={t('components.addCabin.hifi')}
            onChange={(e) => (cabin.hifi = e.target.checked)}
            className="container__checkbox"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            as="textarea"
            rows={1}
            onChange={(e) => (cabin.hifi_description = e.target.value)}
            className="container__description"
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md={2} className="update__label">
          <Form.Check
            type="checkbox"
            label={t('components.addCabin.tv')}
            onChange={(e) => (cabin.tv = e.target.checked)}
            className="container__checkbox"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            as="textarea"
            rows={1}
            onChange={(e) => (cabin.tv_description = e.target.value)}
            className="container__description"
          />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
