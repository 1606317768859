export const CURRENCIES = [
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    country: 'United Arab Emirates',
    locale: 'ar-AE',
  },
  { code: 'ALL', name: 'Albanian Lek', country: 'Albania', locale: 'sq-AL' },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    country: 'Argentina',
    locale: 'es-AR',
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    country: 'Australia',
    locale: 'en-AU',
  },
  {
    code: 'BBD',
    name: 'Barbadian Dollar',
    country: 'Barbados',
    locale: 'en-BB',
  },
  { code: 'BGN', name: 'Bulgarian Lev', country: 'Bulgaria', locale: 'bg-BG' },
  { code: 'BHD', name: 'Bahraini Dinar', country: 'Bahrain', locale: 'ar-BH' },
  { code: 'BRL', name: 'Brazilian Real', country: 'Brazil', locale: 'pt-BR' },
  { code: 'BSD', name: 'Bahamian Dollar', country: 'Bahamas', locale: 'en-BS' },
  { code: 'CAD', name: 'Canadian Dollar', country: 'Canada', locale: 'en-CA' },
  { code: 'CHF', name: 'Swiss Franc', country: 'Switzerland', locale: 'de-CH' },
  { code: 'CLP', name: 'Chilean Peso', country: 'Chile', locale: 'es-CL' },
  { code: 'CNY', name: 'Chinese Yuan', country: 'China', locale: 'zh-CN' },
  { code: 'COP', name: 'Colombian Peso', country: 'Colombia', locale: 'es-CO' },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    country: 'Czech Republic',
    locale: 'cs-CZ',
  },
  { code: 'DKK', name: 'Danish Krone', country: 'Denmark', locale: 'da-DK' },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    country: 'Dominican Republic',
    locale: 'es-DO',
  },
  { code: 'DZD', name: 'Algerian Dinar', country: 'Algeria', locale: 'ar-DZ' },
  { code: 'EGP', name: 'Egyptian Pound', country: 'Egypt', locale: 'ar-EG' },
  { code: 'EUR', name: 'Euro', country: 'Austria', locale: 'de-AT' },
  { code: 'EUR', name: 'Euro', country: 'Belgium', locale: 'nl-BE' },
  { code: 'EUR', name: 'Euro', country: 'France', locale: 'fr-FR' },
  { code: 'EUR', name: 'Euro', country: 'Germany', locale: 'de-DE' },
  { code: 'EUR', name: 'Euro', country: 'Greece', locale: 'el-GR' },
  { code: 'EUR', name: 'Euro', country: 'Spain', locale: 'es-ES' },
  { code: 'EUR', name: 'Euro', country: 'Finland', locale: 'fi-FI' },
  { code: 'EUR', name: 'Euro', country: 'Italy', locale: 'it-IT' },
  { code: 'EUR', name: 'Euro', country: 'Ireland', locale: 'en-IE' },
  { code: 'EUR', name: 'Euro', country: 'Latvia', locale: 'lv-LV' },
  { code: 'EUR', name: 'Euro', country: 'Lithuania', locale: 'lt-LT' },
  { code: 'EUR', name: 'Euro', country: 'Netherlands', locale: 'nl-NL' },
  { code: 'EUR', name: 'Euro', country: 'Portugal', locale: 'pt-PT' },
  { code: 'EUR', name: 'Euro', country: 'Estonia', locale: 'et-EE' },
  {
    code: 'GBP',
    name: 'British Pound',
    country: 'United Kingdom',
    locale: 'en-GB',
  },
  { code: 'GEL', name: 'Georgian Lari', country: 'Georgia', locale: 'ka-GE' },
  { code: 'GHS', name: 'Ghanaian Cedi', country: 'Ghana', locale: 'en-GH' },
  { code: 'GYD', name: 'Guyanese Dollar', country: 'Guyana', locale: 'en-GY' },
  { code: 'HRK', name: 'Croatian Kuna', country: 'Croatia', locale: 'hr-HR' },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    country: 'Hungary',
    locale: 'hu-HU',
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
    country: 'Indonesia',
    locale: 'id-ID',
  },
  { code: 'ILS', name: 'Israeli Shekel', country: 'Israel', locale: 'he-IL' },
  { code: 'INR', name: 'Indian Rupee', country: 'India', locale: 'hi-IN' },
  { code: 'IQD', name: 'Iraqi Dinar', country: 'Iraq', locale: 'ar-IQ' },
  { code: 'ISK', name: 'Icelandic Krona', country: 'Iceland', locale: 'is-IS' },
  { code: 'JPY', name: 'Japanese Yen', country: 'Japan', locale: 'ja-JP' },
  { code: 'KES', name: 'Kenyan Shilling', country: 'Kenya', locale: 'sw-KE' },
  {
    code: 'KRW',
    name: 'South Korean Won',
    country: 'South Korea',
    locale: 'ko-KR',
  },
  { code: 'KWD', name: 'Kuwaiti Dinar', country: 'Kuwait', locale: 'ar-KW' },
  {
    code: 'LKR',
    name: 'Sri Lankan Rupee',
    country: 'Sri Lanka',
    locale: 'si-LK',
  },
  { code: 'MAD', name: 'Moroccan Dirham', country: 'Morocco', locale: 'ar-MA' },
  { code: 'MXN', name: 'Mexican Peso', country: 'Mexico', locale: 'es-MX' },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    country: 'Malaysia',
    locale: 'ms-MY',
  },
  { code: 'NGN', name: 'Nigerian Naira', country: 'Nigeria', locale: 'en-NG' },
  { code: 'NOK', name: 'Norwegian Krone', country: 'Norway', locale: 'nb-NO' },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    country: 'New Zealand',
    locale: 'en-NZ',
  },
  { code: 'OMR', name: 'Omani Rial', country: 'Oman', locale: 'ar-OM' },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    country: 'Philippines',
    locale: 'en-PH',
  },
  { code: 'PLN', name: 'Polish Zloty', country: 'Poland', locale: 'pl-PL' },
  { code: 'RON', name: 'Romanian Leu', country: 'Romania', locale: 'ro-RO' },
  { code: 'RUB', name: 'Russian Ruble', country: 'Russia', locale: 'ru-RU' },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    country: 'Saudi Arabia',
    locale: 'ar-SA',
  },
  { code: 'SEK', name: 'Swedish Krona', country: 'Sweden', locale: 'sv-SE' },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    country: 'Singapore',
    locale: 'en-SG',
  },
  { code: 'THB', name: 'Thai Baht', country: 'Thailand', locale: 'th-TH' },
  { code: 'TRY', name: 'Turkish Lira', country: 'Turkey', locale: 'tr-TR' },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    country: 'Trinidad and Tobago',
    locale: 'en-TT',
  },
  {
    code: 'UAH',
    name: 'Ukrainian Hryvnia',
    country: 'Ukraine',
    locale: 'uk-UA',
  },
  {
    code: 'USD',
    name: 'United States Dollar',
    country: 'United States',
    locale: 'en-US',
  },
  { code: 'UYU', name: 'Uruguayan Peso', country: 'Uruguay', locale: 'es-UY' },
  { code: 'VND', name: 'Vietnamese Dong', country: 'Vietnam', locale: 'vi-VN' },
  { code: 'VUV', name: 'Vanuatu Vatu', country: 'Vanuatu', locale: 'bi-VU' },
  { code: 'WST', name: 'Samoan Tala', country: 'Samoa', locale: 'sm-WS' },
  {
    code: 'XAF',
    name: 'Central African CFA Franc',
    country: 'Cameroon',
    locale: 'fr-CM',
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    country: 'Saint Lucia',
    locale: 'en-LC',
  },
  {
    code: 'XOF',
    name: 'West African CFA Franc',
    country: 'Senegal',
    locale: 'fr-SN',
  },
  {
    code: 'XPF',
    name: 'CFP Franc',
    country: 'French Polynesia',
    locale: 'fr-PF',
  },
  { code: 'YER', name: 'Yemeni Rial', country: 'Yemen', locale: 'ar-YE' },
  {
    code: 'ZAR',
    name: 'South African Rand',
    country: 'South Africa',
    locale: 'en-ZA',
  },
  { code: 'ZMW', name: 'Zambian Kwacha', country: 'Zambia', locale: 'en-ZM' },
  {
    code: 'ZWL',
    name: 'Zimbabwean Dollar',
    country: 'Zimbabwe',
    locale: 'en-ZW',
  },
];
