// import libraries
import React from 'react';
import Card from '../components/card';

/*
Card list component
@params :
    - data {array of objects} => all boats in the user network.
    - modelSearched {string} => user input, all characters.
    - minSize {integer} => user input, only numbers. Compare to actual size.
    - maxSize {integer} => user input, only numbers. Compare to actual size.
    - minPrice {integer} => user input, only numbers.
    - maxPrice {integer} => user input, only numbers.
    - selectedStatus {array of integer} => corresponds to the current status of the boat in the data.
    - selectedTypology {array of integer} => corresponds to the type of boat in the data.

List of cards to display.
Several filters are applied according to parameters.
*/

export default function CardList({
  data,
  modelSearched,
  minSize,
  maxSize,
  minPrice,
  maxPrice,
  selectedStatus,
  selectedTypology,
  selectedFilter,
  selectedUsers,
  selectedAgencies,
}) {
  return (
    <div className="boats__grid">
      {/* filter based on model name  */}
      {data
        .filter((boat) => {
          return modelSearched.toLowerCase() === ''
            ? boat
            : boat.model
                .toLowerCase()
                .replace(',', '')
                .replace('.', '')
                .includes(modelSearched);
        }) // filter based on boat size according to filled-in fields
        .filter((boat) => {
          if (minSize !== '' && maxSize !== '') {
            return boat.size <= maxSize && boat.size >= minSize;
          } else if (minSize === '' && maxSize !== '') {
            return boat.size <= maxSize;
          } else if (maxSize === '' && minSize !== '') {
            return boat.size >= minSize;
          } else {
            return boat;
          }
        }) // filter based on boat price according to filled-in fields
        .filter((boat) => {
          if (minPrice !== '' && maxPrice !== '') {
            return boat.price <= maxPrice && boat.price >= minPrice;
          } else if (minPrice === '' && maxPrice !== '') {
            return boat.price <= maxPrice;
          } else if (maxPrice === '' && minPrice !== '') {
            return boat.price >= minPrice;
          } else {
            return boat;
          }
        }) // filter based on selected boat status
        .filter((boat) => selectedStatus?.includes(boat.status)) // filter based on selected boat types
        .filter((boat) => selectedTypology?.includes(boat.typology)) // display of boat list after filters
        .filter((boat) =>
          selectedFilter === 'agency'
            ? selectedUsers?.includes(boat.user)
            : boat
        )
        .filter((boat) =>
          selectedFilter === 'network'
            ? selectedAgencies?.includes(boat.agency)
            : boat
        )
        .map((boat) => {
          return <Card key={boat.id} boat={boat} />;
        })}
    </div>
  );
}
