import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { API_ROUTES } from './utils/constants';

i18n
  .use(HttpBackend) // Utilisation du backend HTTP pour charger les traductions dynamiquement
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en', // Langue par défaut
    fallbackLng: 'en', // Langue de secours
    backend: {
      loadPath: `${API_ROUTES.LOCALES.GET.LOCALES}/{{lng}}/{{ns}}.json`, // Chemin des fichiers de traduction
    },
    interpolation: {
      escapeValue: false, // React gère déjà l'échappement
    },
  });

export default i18n;
