// import libraries
import React, { useEffect, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import components
import ValidForm from '../../components/validForm';
import CreateDetails from '../../components/createDetails';
// import utils
import { API_ROUTES } from '../../utils/constants';
import { getData, postCreateBoat, postDataID } from '../../utils/api';

/*
Create boat page
- Create a new boat from the necessary data.
- Assign this new boat to the user.
- Redirects to the boat modification page.
*/

export default function CreateBoat() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState({
    brand: `${t('pages.boat.edit.details.brand')}`,
    model: `${t('pages.boat.edit.details.model')}`,
    id_status: 3,
    year: 'YYYY',
    price: 0,
    act_length: 0,
    id_typology: 1,
    id_flag: 1,
    id_keel: 0,
    locale: JSON.parse(localStorage.getItem('ids')).locale,
    town: JSON.parse(localStorage.getItem('ids')).agency,
    country: JSON.parse(localStorage.getItem('ids')).country,
  });
  const [status, setStatus] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [flags, setFlags] = useState([]);
  const [keels, setKeels] = useState([]);
  // get data for select
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await getData(API_ROUTES.BOATS.GET.STATUS);
        setStatus(response.data);
        const response0 = await getData(API_ROUTES.BOATS.GET.TYPOLOGIES);
        setTypologies(response0.data);
        const response1 = await getData(API_ROUTES.BOATS.GET.FLAGS);
        setFlags(response1.data);
        const getKeels = await getData(API_ROUTES.BOATS.GET.KEELS);
        setKeels(getKeels.data);
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // redirect to login page if error
        }
        console.log('createboat index L48 error ', error);
      }
    };
    fetchData();
    setIsLoading(false);
  }, [navigate]);
  // open accordion
  const [isDisplay, setIsDisplay] = useState({
    0: true,
  });
  const handleHeader = (id) => {
    setIsDisplay({ ...isDisplay, [`${id}`]: !isDisplay[`${id}`] });
  };
  // click on save
  const handleSave = async (e) => {
    e.preventDefault();
    const response = await postCreateBoat(
      details,
      API_ROUTES.BOATS.POST.DETAILS,
      JSON.parse(localStorage.getItem('ids')).user,
    );
    if (response.data.id) {
      const data = {
        id_events: 1,
        date: new Date(),
        description: 'Boat creation',
      };
      await postDataID(API_ROUTES.BOATS.POST.EVENTS, data, response.data.id);
      navigate(`/boats/${response?.data.id}`); // navigate to the page of new boat when it's created
    }
  };

  // click on reset
  const handleCancel = () => {
    setDetails({
      brand: `${t('pages.boat.edit.details.brand')}`,
      model: `${t('pages.boat.edit.details.model')}`,
      id_status: 3,
      year: 'YYYY',
      price: 0,
      act_length: 0,
      id_typology: 1,
      id_flag: 1,
      id_keel: 0,
      locale: JSON.parse(localStorage.getItem('ids')).locale,
    });
  };

  return (
    <div className="boat">
      {isLoading ? (
        <h1 className="mb-5 mt-5">Loading...</h1>
      ) : (
        <h1 className="mb-5 mt-5">{t('components.createBoat')}</h1>
      )}
      <Accordion
        as={Form}
        id="form"
        onSubmit={(e) => handleSave(e)}
        onReset={handleCancel}
        alwaysOpen
        className="boat__accordion"
      >
        <CreateDetails
          data={details}
          setData={setDetails}
          handleHeader={handleHeader}
          isDisplay={isDisplay}
          statusList={status}
          typologiesList={typologies}
          flagsList={flags}
          keelsList={keels}
        />
      </Accordion>
      <div className="buttons__container">
        <ValidForm form="form" disabled={false} />
      </div>
    </div>
  );
}
