// import libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io';
import { useTranslation } from 'react-i18next';
// import composants
import EditButtonGroup from '../editButtonGroup';
// import utils
import { getDataID } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/transactionCommercial.scss';

/*
TransactionCommercial Component
Display commercial informations of a specific transaction by id
*/

export default function TransactionCommercial({
  isLoading,
  user,
  startCompromise,
  expireDate,
  priceOffer,
  commission,
  id_boat,
  setDataUpdate,
  handleSubmit,
  handleReset,
  show,
  setShow,
  bgToast,
  textToast,
  rightUpdate,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getDataID(API_ROUTES.USERS.GET.USER, user);
        setData(response.data);
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // redirect to login page if error 403
        } else {
          console.log('people index L120 error ', error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [navigate, user, id_boat]);

  return (
    <div className="transactionCommercial">
      {loading ? (
        <h2>{t('components.transactionCommercial.loading')}</h2>
      ) : (
        <React.Fragment>
          <h2>
            {data?.firstname} {data?.lastname}
          </h2>
          <hr />
          <Form
            id="formTransactionCommercial"
            onSubmit={handleSubmit}
            onReset={handleReset}
            className="transactionCommercial__core"
          >
            <div className="transactionCommercial__core__info">
              <span className="transactionCommercial__core__info__label">
                {t('components.transactionCommercial.commission')}
              </span>
              <div className="transactionCommercial__core__infoGroup">
                <span>
                  {`${((commission / priceOffer) * 100).toFixed(2)} %`}
                </span>
                <span>
                  {new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0,
                  }).format(commission)}
                </span>
              </div>
            </div>
            <Form.Group
              controlId="dateExpirationCompromis"
              className="transactionCommercial__core__info"
            >
              <Form.Label className="transactionCommercial__core__info__label">
                {t('components.transactionCommercial.compromiseDate')}
              </Form.Label>
              <Form.Control
                type="date"
                disabled={!isEditing}
                size="sm"
                value={startCompromise}
                onChange={(e) =>
                  setDataUpdate((prev) => ({
                    ...prev,
                    start_date_compromise_creation: e.target.value,
                  }))
                }
                className="transactionCommercial__core__info__date"
              />
            </Form.Group>
            <Form.Group
              controlId="dateExpirationCompromis"
              className="transactionCommercial__core__info"
            >
              <Form.Label className="transactionCommercial__core__info__label">
                {t('components.transactionCommercial.expirationDate')}
              </Form.Label>
              <Form.Control
                type="date"
                disabled={!isEditing}
                size="sm"
                value={expireDate}
                onChange={(e) =>
                  setDataUpdate((prev) => ({
                    ...prev,
                    compromise_expiry_date: e.target.value,
                  }))
                }
                className="transactionCommercial__core__info__date"
              />
            </Form.Group>
          </Form>
          <hr />
          <ToastContainer
            position="bottom-end"
            className="transactionCore__toastContainer"
          >
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              bg={bgToast}
              autohide
            >
              <Toast.Body>
                <span className="me-auto">{textToast}</span>
              </Toast.Body>
            </Toast>
          </ToastContainer>
          {rightUpdate && (
            <EditButtonGroup
              editIcon={<FaEdit />}
              editButtonName={t('components.transactionCommercial.edit')}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              valideIcon={<IoMdCheckmarkCircleOutline />}
              valideButtonName={t('components.transactionCommercial.save')}
              cancelIcon={<IoMdCloseCircleOutline />}
              cancelButtonName={t('components.transactionCommercial.cancel')}
              form="formTransactionCommercial"
              isLoading={isLoading}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}
