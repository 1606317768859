// import libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
// import components
import App from './app.jsx';
import './i18n';

// Retrieving the tag to insert the application
const root = ReactDOM.createRoot(document.getElementById('root'));
// Add application to tag
root.render(<App />);
