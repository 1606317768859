// import libraries
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import components
import CardList from '../../components/cardList';
import Search from '../../components/search';
// import utils
import { API_ROUTES } from '../../utils/constants';
import { getAllAgency, getData, getDataParams } from '../../utils/api';
// import css
import '../../assets/styles/boats.scss';

/*
Boats page
Display the search form and all matching boats as a map grid 
with clickable buttons to access details and the boat owner.
*/

export default function Boats() {
  const navigate = useNavigate();
  // store data to display + filtered data
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // features
  const featureBoatsNetwork = 'boats_network';
  const featureBoatsAgencyOther = 'boats_agency';
  const [auth, setAuth] = useState([]);

  useEffect(() => {
    const fetchAuthoriz = async () => {
      try {
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
    };
    fetchAuthoriz();
  }, []);
  // receive filter form search components
  const [isActiveZone, setIsActiveZone] = useState('');
  const [model, setModel] = useState('');
  const [minSize, setMinSize] = useState('');
  const [maxSize, setMaxSize] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([1]);
  const [selectedTypology, setSelectedTypology] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [filterData, setFilterData] = useState();
  // fetch data to display
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const responseLevel = await getAllAgency(API_ROUTES.USERS.GET.AGENCY);
        let network = [];
        responseLevel.data.networkid.forEach((element) => {
          network.push(element.agencyID);
        });
        const params = new URLSearchParams({
          agency: network,
        });
        const responseBoat = await getDataParams(
          API_ROUTES.BOATS.GET.CARDLIST,
          params
        );
        setData(responseBoat.data);
        setIsActiveZone('boats');
        const data = await getData(API_ROUTES.USERS.GET.ALLNETWORK);
        setFilterData(data.data);
        setSelectedUsers(data.data.users.map((user) => user.id));
        setSelectedAgencies(data.data.agencies.map((agency) => agency.id));
      } catch (error) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  // filter by clicked zone
  useEffect(() => {
    const filter = async () => {
      if (isActiveZone === 'boats') {
        const filterZone = data.filter(
          (boat) => boat.user === JSON.parse(localStorage.getItem('ids')).user
        );
        setFilteredData(filterZone);
      } else if (isActiveZone === 'agency') {
        const filterZone = data.filter(
          (boat) =>
            boat.agency === JSON.parse(localStorage.getItem('ids')).id_agency
        );
        setFilteredData(filterZone);
      } else {
        setFilteredData(data);
      }
    };
    filter();
    // eslint-disable-next-line
  }, [isActiveZone]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center boats">
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          className="boats__spinner"
        ></Spinner>
      ) : (
        <React.Fragment>
          {/* Search component with arg */}
          <Search
            setAllData={setFilteredData}
            isActiveZone={isActiveZone}
            setIsActiveZone={setIsActiveZone}
            setModel={setModel}
            setMinSize={setMinSize}
            setMaxSize={setMaxSize}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedTypology={selectedTypology}
            setSelectedTypology={setSelectedTypology}
            filterData={filterData}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            selectedAgencies={selectedAgencies}
            setSelectedAgencies={setSelectedAgencies}
          />
          {/* List of cards with all datas */}
          {(auth.includes(featureBoatsNetwork) ||
            auth.includes(featureBoatsAgencyOther)) && (
            <CardList
              data={filteredData}
              modelSearched={model}
              minSize={minSize}
              maxSize={maxSize}
              minPrice={minPrice}
              maxPrice={maxPrice}
              selectedStatus={selectedStatus}
              selectedTypology={selectedTypology}
              selectedFilter={isActiveZone}
              selectedUsers={selectedUsers}
              selectedAgencies={selectedAgencies}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}
