// import libraries
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

/*
Add tank component
@params :
    - tankTypes {array of objects} => all types of tank possible.
    - material {array of objects} => all material possible.
    - tank {object} => new tank to add.
    - deleteItem {function} => delete this tank of array.

All fields needed to create a new tank.
*/

export default function AddTank({ tanksTypes, material, tank, deleteItem }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addTank.title')}
        </span>
        <Button
          variant="danger"
          onClick={() => deleteItem(tank.id)}
          className="canDelete__button"
        >
          <FaRegTrashAlt />
        </Button>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addTank.capacity')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addTank.capacity')}
            onChange={(e) => (tank.capacity = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addTank.type')}
          </Form.Label>
          <Form.Select onChange={(e) => (tank.id_type = e.target.value)}>
            {tanksTypes.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={3}>
            {t('components.addTank.material')}
          </Form.Label>
          <Form.Select onChange={(e) => (tank.id_material = e.target.value)}>
            <option value={0}>{t('components.addTank.none')}</option>
            {material.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
