// import libraries
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

/*
Add bathroom component
@params :
    - position {array of objects} => all position possible.
    - bathroom {object} => new bathroom to add.
    - deleteItem {function} => delete this bathroom of array.

All fields needed to create a new bathroom.
*/

export default function AddBathroom({ position, bathroom, deleteItem }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addBathroom.title')}
        </span>
        <Button
          variant="danger"
          onClick={() => deleteItem(bathroom.id)}
          className="canDelete__button"
        >
          <FaRegTrashAlt />
        </Button>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} md={4} className="update__label">
          <Form.Label column md={3}>
            {t('components.addBathroom.position')}
          </Form.Label>
          <Form.Select
            onChange={(e) => (bathroom.id_position = e.target.value)}
          >
            {position.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column>
            {t('components.addBathroom.description')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addBathroom.description')}
            onChange={(e) => (bathroom.description = e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column>{t('components.addBathroom.toilets')}</Form.Label>
          <Form.Control
            placeholder={t('components.addBathroom.toilets')}
            onChange={(e) => (bathroom.toilets = e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column>{t('components.addBathroom.shower')}</Form.Label>
          <Form.Control
            placeholder={t('components.addBathroom.shower')}
            onChange={(e) => (bathroom.shower = e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column>{t('components.addBathroom.bathtub')}</Form.Label>
          <Form.Control
            placeholder={t('components.addBathroom.bathtub')}
            onChange={(e) => (bathroom.bathtub = e.target.value)}
          />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
