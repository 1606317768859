// import libraries
import React from 'react';
import {
  Accordion,
  Row,
  Form,
  Col,
  InputGroup,
  Dropdown,
} from 'react-bootstrap';
import { CURRENCIES } from '../utils/currencies';
import { useTranslation } from 'react-i18next';

/*
Boat detail component at creation
@params :
    - data {object} => object values of new boat.
    - setData {function} => change value of new boat object.
    - handleHeader {function} => update value of display body and hidden header of accordion.
    - isDisplay {boolean} => display value of accordion.
    - statusList {array} => array of all status.
    - typologiesList {array} => array of all typology.
    - flagsList {array} => array of all flag.

Display all field to create new boat.
Update values in change.
*/

export default function CreateDetails({
  data,
  setData,
  handleHeader,
  isDisplay,
  statusList,
  typologiesList,
  flagsList,
  keelsList,
}) {
  const { t } = useTranslation();
  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header onClick={() => handleHeader(0)} as="div">
        <section className="accordion__specifications">
          <div className="specifications__info">
            <span className="info__strong">
              {data.brand} - {data.model}
            </span>
            <span className="info__status">
              {data.name && `"${data.name}" - `}
              {t('pages.boat.display.details.status')}
              {data &&
                statusList.length > 0 &&
                statusList.find((element) => element.id === data.id_status)
                  .value}
            </span>
          </div>
          <hr />
          {isDisplay['0'] && (
            <React.Fragment>
              <Row>
                <span className="col-4">
                  {t('pages.boat.display.details.year')}
                  {data.year}
                </span>
                <span className="col-4">
                  {t('pages.boat.display.details.typology')}
                  {t(
                    `${
                      data &&
                      typologiesList.length > 0 &&
                      typologiesList.find(
                        (element) => element.id === data.id_typology,
                      ).tag
                    }`,
                  )}
                </span>
                <span className="col-4">
                  {t('pages.boat.display.details.flag')}
                  {t(
                    `${
                      data &&
                      flagsList.length > 0 &&
                      flagsList.find((element) => element.id === data.id_flag)
                        .tag
                    }`,
                  )}
                </span>
              </Row>
              <Row>
                <div className="col-4">
                  {data.draft > 0 && (
                    <span className="specifications__measures">
                      {t('pages.boat.display.details.draft')}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.draft / 100)}
                    </span>
                  )}
                  {data.air_draft > 0 && (
                    <span className="specifications__measures">
                      {t('pages.boat.display.details.airDraft')}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.air_draft / 100)}
                    </span>
                  )}
                </div>
                <div className="col-4">
                  <span className="specifications__measures">
                    {t('pages.boat.display.details.length')}
                    {new Intl.NumberFormat('fr-FR', {
                      style: 'unit',
                      unit: 'meter',
                    }).format(data.act_length / 100)}
                  </span>
                  {data.act_width > 0 && (
                    <span className="specifications__measures">
                      {t('pages.boat.display.details.width')}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.act_width / 100)}
                    </span>
                  )}
                </div>
                <div className="col-4">
                  {data.adm_length > 0 && (
                    <span className="specifications__measures">
                      {t('pages.boat.display.details.admLength')}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.adm_length / 100)}
                    </span>
                  )}
                  {data.adm_width > 0 && (
                    <span className="specifications__measures">
                      {t('pages.boat.display.details.admWidth')}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'unit',
                        unit: 'meter',
                      }).format(data.adm_width / 100)}
                    </span>
                  )}
                </div>
              </Row>
              <Row>
                <span className="col-4">
                  {data.headroom &&
                    `${t(
                      'pages.boat.display.details.headroom',
                    )}${new Intl.NumberFormat('fr-FR', {
                      style: 'unit',
                      unit: 'meter',
                    }).format(data.headroom / 100)}`}
                </span>
                <span className="col-4">
                  {data.weight &&
                    `${t(
                      'pages.boat.display.details.weight',
                    )}${new Intl.NumberFormat('fr-FR', {
                      style: 'unit',
                      unit: 'kilogram',
                    }).format(data.weight)}`}
                </span>
                <span className="col-4">
                  {data &&
                    `${t(
                      'pages.boat.display.details.taxNav',
                    )}${new Intl.NumberFormat('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                      maximumFractionDigits: 0,
                    }).format(0)}`}
                </span>
              </Row>
              <Row>
                <span className="col-2">
                  {t('pages.boat.display.details.tv')}
                  {data?.tv ? 'Yes' : 'No'}
                </span>
                <span className="col-6">{data?.tv_description}</span>
                <span className="col-4">
                  {t('pages.boat.display.details.teakDeck')}
                  {data?.teak_deck ? 'Yes' : 'No'}
                </span>
              </Row>
              <Row>
                <span className="col-4">
                  {t('pages.boat.display.details.keel')}
                  {t(
                    `${
                      data && keelsList.length > 0 && data.id_keel > 0
                        ? keelsList.find(
                            (element) => element.id === data.id_keel,
                          ).tag
                        : 'pages.boat.edit.details.none'
                    }`,
                  )}
                </span>
                <span className="col-2">
                  {t('pages.boat.display.details.berthPlace')}
                  {data.berth_place ? `${t('true')}` : `${t('false')}`}
                </span>
                <span className="col-6">{data?.berth_place_description}</span>
              </Row>
              <Row>
                <span className="col-6">
                  {data.town &&
                    `${t('pages.boat.display.details.town')}${data.town}`}
                </span>
                <span className="col-6">
                  {data.country &&
                    `${t('pages.boat.display.details.country')}${data.country}`}
                </span>
              </Row>
              <Row>
                <span className="col-4">
                  {data.serial_number &&
                    `${t('pages.boat.display.details.serialNumber')}${
                      data.serial_number
                    }`}
                </span>
                <span className="col-4">
                  {data.registration &&
                    `${t('pages.boat.display.details.registration')}${
                      data.registration
                    }`}
                </span>
              </Row>
              <hr />
              <div className="specifications__info">
                <span className="info__strong">
                  {t('pages.boat.display.details.price')}
                  {new Intl.NumberFormat(`${data.locale}`, {
                    style: 'currency',
                    currency: `${
                      CURRENCIES.find(
                        (currency) => currency.locale === data.locale,
                      ).code
                    }`,
                    maximumFractionDigits: 0,
                  }).format(data.price)}{' '}
                  {data.ttc
                    ? `${t('pages.boat.display.details.ttc')}`
                    : `${t('pages.boat.display.details.ht')}`}
                </span>
              </div>
            </React.Fragment>
          )}
        </section>
      </Accordion.Header>
      <Accordion.Body className="accordion__update">
        <Row className="mb-4">
          <Form.Group as={Col} md={2}>
            <Form.Control
              placeholder={t('pages.boat.edit.details.brand')}
              onChange={(e) => setData({ ...data, brand: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              placeholder={t('pages.boat.edit.details.model')}
              onChange={(e) => setData({ ...data, model: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Control
              placeholder={t('pages.boat.edit.details.name')}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Select
              value={data.id_status}
              onChange={(e) => {
                setData({
                  ...data,
                  id_status: parseInt(e.target.value, 10),
                });
              }}
            >
              {statusList.map((element, index) => {
                return (
                  <option key={index} value={element.id}>
                    {t(element.tag)}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Row>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              {t('pages.boat.edit.details.year')}
            </Form.Label>
            <Form.Control
              placeholder={t('pages.boat.edit.details.year')}
              maxLength={4}
              onChange={(e) => {
                setData({
                  ...data,
                  year: e.target.value.replace(/[^0-9]/g, ''),
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Select
              value={data.id_typology}
              onChange={(e) => {
                setData({
                  ...data,
                  id_typology: parseInt(e.target.value, 10),
                });
              }}
            >
              {typologiesList.map((element, index) => {
                return (
                  <option key={index} value={element.id}>
                    {t(element.tag)}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Select
              value={data.id_flag}
              onChange={(e) => {
                setData({ ...data, id_flag: parseInt(e.target.value, 10) });
              }}
            >
              {flagsList.map((element, index) => {
                return (
                  <option key={index} value={element.id}>
                    {t(element.tag)}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              {t('pages.boat.edit.details.draft')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.draft')}
                onChange={(e) => {
                  setData({
                    ...data,
                    draft: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              {t('pages.boat.edit.details.length')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.length')}
                onChange={(e) => {
                  setData({
                    ...data,
                    act_length: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={5}>
              {t('pages.boat.edit.details.admLength')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.admLength')}
                onChange={(e) => {
                  setData({
                    ...data,
                    adm_length: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={3}>
              {t('pages.boat.edit.details.airDraft')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.airDraft')}
                onChange={(e) => {
                  setData({
                    ...data,
                    air_draft: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              {t('pages.boat.edit.details.width')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.width')}
                onChange={(e) => {
                  setData({
                    ...data,
                    act_width: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={5}>
              {t('pages.boat.edit.details.admWidth')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.admWidth')}
                onChange={(e) => {
                  setData({
                    ...data,
                    adm_width: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={3}>
              {t('pages.boat.edit.details.headroom')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.headroom')}
                onChange={(e) => {
                  setData({
                    ...data,
                    headroom: e.target.value * 100,
                  });
                }}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={2}>
              {t('pages.boat.edit.details.weight')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.weight')}
                onChange={(e) => {
                  setData({ ...data, weight: e.target.value });
                }}
              />
              <InputGroup.Text>kg</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={5}>
              {t('pages.boat.edit.details.taxNav')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={t('pages.boat.edit.details.taxNav')}
                defaultValue={0}
                readOnly
              />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label" md={8}>
            <Form.Check
              type="checkbox"
              label={t('pages.boat.edit.details.tv')}
              checked={data?.tv || false}
              onChange={(e) => {
                setData({ ...data, tv: e.target.checked });
              }}
              className="container__checkbox"
            />
            <Form.Control
              as="textarea"
              rows={1}
              disabled={!data?.tv}
              defaultValue={data?.tv_description}
              onChange={(e) => {
                setData({ ...data, tv_description: e.target.value });
              }}
              className="container__description"
            />
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Check
              type="checkbox"
              label={t('pages.boat.edit.details.teakDeck')}
              checked={data?.teak_deck || false}
              onChange={(e) => {
                setData({ ...data, teak_deck: e.target.checked });
              }}
              className="container__checkbox"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label">
            <Form.Select
              defaultValue={data?.id_keel}
              onChange={(e) => {
                setData({
                  ...data,
                  id_keel: e.target.value,
                });
              }}
            >
              <option value={null}>{t('pages.boat.edit.details.none')}</option>
              {keelsList.map((element, index) => {
                return (
                  <option key={index} value={element.id}>
                    {t(element.tag)}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="update__label" md={8}>
            <Form.Check
              type="checkbox"
              label={t('pages.boat.edit.details.berthPlace')}
              checked={data?.berth_place || false}
              onChange={(e) =>
                setData({
                  ...data,
                  berth_place: e.target.checked,
                })
              }
              className="container__checkbox"
            />
            <Form.Control
              as="textarea"
              rows={1}
              disabled={!data?.berth_place}
              defaultValue={data?.berth_place_description}
              onChange={(e) =>
                setData({
                  ...data,
                  berth_place_description: e.target.value,
                })
              }
              className="container__description"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="update__label" md={8}>
            <Form.Control
              placeholder={t('pages.boat.edit.details.town')}
              rows={1}
              defaultValue={data?.town}
              onChange={(e) =>
                setData({
                  ...data,
                  town: e.target.value,
                })
              }
              className="container__description"
            />
            <Form.Control
              placeholder={t('pages.boat.edit.details.country')}
              rows={1}
              defaultValue={data?.country}
              onChange={(e) =>
                setData({
                  ...data,
                  country: e.target.value,
                })
              }
              className="container__description"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={4}>
              {t('pages.boat.edit.details.serialNumber')}
            </Form.Label>
            <Form.Control
              placeholder={t('pages.boat.edit.details.serialNumber')}
              defaultValue={
                data.serial_number !== 0 ? data.serial_number : null
              }
              onChange={(e) => {
                setData({
                  ...data,
                  serial_number: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Label column md={4}>
              {t('pages.boat.edit.details.registration')}
            </Form.Label>
            <Form.Control
              placeholder={t('pages.boat.edit.details.registration')}
              defaultValue={data.registration !== 0 ? data.registration : null}
              onChange={(e) => {
                setData({
                  ...data,
                  registration: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <hr />
        <Row>
          <Form.Group as={Col} className="update__label">
            <Form.Label>{t('pages.boat.edit.details.price')}</Form.Label>
            <Form.Control
              placeholder={t('pages.boat.edit.details.price')}
              defaultValue={data.price}
              onChange={(e) => {
                setData({ ...data, price: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Dropdown
              onSelect={(eventKey) => {
                setData({
                  ...data,
                  locale: eventKey,
                });
              }}
              className="update__label__currencies"
            >
              <Dropdown.Toggle id="dropdown-currency">
                <span
                  className={`fi fi-${data.locale
                    .split('-')[1]
                    .toLowerCase()} update__label__currencies__menu__flag`}
                ></span>
                {data.locale
                  ? `${
                      CURRENCIES.filter(
                        (item) => item.locale === data.locale,
                      )[0].code
                    } | ${
                      CURRENCIES.filter(
                        (item) => item.locale === data.locale,
                      )[0].country
                    } - ${
                      CURRENCIES.filter(
                        (item) => item.locale === data.locale,
                      )[0].name
                    }`
                  : 'Select currency*'}
              </Dropdown.Toggle>

              <Dropdown.Menu className="update__label__currencies__menu">
                {CURRENCIES.map((item, index) => {
                  const countryCode = item.locale.split('-')[1].toLowerCase();
                  return (
                    <Dropdown.Item key={index} eventKey={item.locale}>
                      <span
                        className={`fi fi-${countryCode} update__label__currencies__menu__flag`}
                      ></span>
                      {item.code} | {item.country} - {item.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group as={Col} className="update__label">
            <Form.Check
              type="checkbox"
              label={t('pages.boat.edit.details.ttc')}
              checked={data?.ttc || false}
              onChange={(e) =>
                setData({
                  ...data,
                  ttc: e.target.checked,
                })
              }
              className="container__checkbox"
            />
          </Form.Group>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
