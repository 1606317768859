// import libraries
import React from 'react';
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

/*
Add sail component
@params :
    - sailsTypes {array of objects} => all sailsTypes possible.
    - sail {object} => new sail to add.
    - deleteItem {function} => delete this sail of array.

All fields needed to create a new sail.
*/

export default function AddSail({ sailsTypes, sail, deleteItem }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addSail.title')}
        </span>
        <Button
          variant="danger"
          onClick={() => deleteItem(sail.id)}
          className="canDelete__button"
        >
          <FaRegTrashAlt />
        </Button>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addSail.type')}
          </Form.Label>
          <Form.Select onChange={(e) => (sail.id_sail_type = e.target.value)}>
            {sailsTypes.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addSail.brand')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addSail.brand')}
            onChange={(e) => (sail.brand = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={2}>
            {t('components.addSail.area')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={t('components.addSail.area')}
              onChange={(e) => (sail.area = e.target.value)}
            />
            <InputGroup.Text>m²</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md={2} className="update__label">
          <Form.Label column md={4}>
            {t('components.addSail.year')}
          </Form.Label>
          <Form.Control
            placeholder={t('components.addSail.year')}
            onChange={(e) => (sail.year = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column>{t('components.addSail.description')}</Form.Label>
          <Form.Control
            placeholder={t('components.addSail.description')}
            onChange={(e) => (sail.description = e.target.value)}
          />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
