// import libraries
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

/*
Add kitchen component
@params :
    - hotplates {array of objects} => all hotplates possible.
    - oven {array of objects} => all oven possible.
    - kitchen {object} => new kitchen to add.
    - deleteItem {function} => delete this kitchen of array.

All fields needed to create a new kitchen.
*/

export default function AddKitchen({ hotplates, oven, kitchen, deleteItem }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col className="update__canDelete">
        <span className="accordion__subtitle">
          {t('components.addKitchen.title')}
        </span>
        <Button
          variant="danger"
          onClick={() => deleteItem(kitchen.id)}
          className="canDelete__button"
        >
          <FaRegTrashAlt />
        </Button>
      </Col>
      <Row className="mb-3">
        <Form.Group as={Col} md={4} className="update__label">
          <Form.Label column md={3}>
            {t('components.addKitchen.hotplates')}
          </Form.Label>
          <Form.Select
            onChange={(e) => (kitchen.id_hotplates = e.target.value)}
          >
            {hotplates.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            as="textarea"
            rows={1}
            onChange={(e) => (kitchen.hotplates_description = e.target.value)}
            className="container__description"
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md={4} className="update__label">
          <Form.Label column md={6}>
            {t('components.addKitchen.quantityOven')}
          </Form.Label>
          <Form.Control
            placeholder="Quantity"
            onChange={(e) => (kitchen.quantity_oven = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} className="update__label">
          <Form.Label column md={3}>
            {t('components.addKitchen.oven')}
          </Form.Label>
          <Form.Select onChange={(e) => (kitchen.id_oven = e.target.value)}>
            {oven.map((element, index) => {
              return (
                <option key={index} value={element.id}>
                  {t(element.tag)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Check
            type="checkbox"
            label={t('components.addKitchen.microwave')}
            onChange={(e) => (kitchen.microwave = e.target.checked)}
            className="container__checkbox"
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={6}>
            {t('components.addKitchen.quantityFridge')}
          </Form.Label>
          <Form.Control
            placeholder="Quantity"
            onChange={(e) => (kitchen.quantity_fridge = e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} className="update__label">
          <Form.Label column md={6}>
            {t('components.addKitchen.quanityFreezer')}
          </Form.Label>
          <Form.Control
            placeholder="Quantity"
            onChange={(e) => (kitchen.quantity_freezer = e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="update__label">
          <Form.Control
            as="textarea"
            rows={1}
            onChange={(e) => (kitchen.description = e.target.value)}
            className="container__description"
          />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}
