// import libraries
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Form, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import components

// import utils
import { getData, postDataUser, putDataUser } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import assets

// import css
import '../../assets/styles/xml.scss';

/*
XML page
WIP
Display nothing for the moment.
*/

const initialState = {
  id: '',
  range: '',
  file: null,
};

// Reducer pour gérer l'état du formulaire
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.value };
    case 'UPDATE':
      return { ...state, [action.field]: action.value };
    case 'SET_FILE':
      return { ...state, file: action.file };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export default function XML() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [data, setData] = useState();
  const [template, setTemplate] = useState();
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const networkInputRef = useRef(null);
  const agencyInputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          // fetch existing xml files
          const data = await getData(API_ROUTES.XML.GET.FILES);
          setData(data.data);
          const template = await getData(API_ROUTES.XML.GET.TEMPLATES);
          setTemplate(template.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }, // eslint-disable-next-line
    [load],
  );

  const handleFileChange = (e, range) => {
    setLoad(!load);
    const file = e.target.files[0];
    dispatch({ type: 'SET_FILE', file });
    dispatch({ type: 'UPDATE', field: 'range', value: range });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('range', state.range);
    if (state.file) {
      formData.append('file', state.file);
    }

    const hasNetworkTemplate = template.some(
      (item) => item.id_network !== null,
    );
    const hasAgencyTemplate = template.some((item) => item.id_agency !== null);

    let response;
    if (hasNetworkTemplate || hasAgencyTemplate) {
      response = await putDataUser(API_ROUTES.XML.PUT.FILES, formData);
    } else {
      response = await postDataUser(API_ROUTES.XML.POST.FILES, formData);
    }

    if (response.status === 201 || response.status === 200) {
      dispatch({ type: 'RESET' });
      if (networkInputRef.current) {
        networkInputRef.current.value = '';
      }
      if (agencyInputRef.current) {
        agencyInputRef.current.value = '';
      }
      setLoad(!load);
    }
  };

  const handleGenerate = async () => {
    const response = await getData(API_ROUTES.XML.GET.XML);
    setLoad(!load);
    if (response.status === 200) {
      setShow(true);
    }
  };

  return (
    <div className="xml">
      <h1 className="mb-5">{t('pages.xml.manage')}</h1>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <h2>{t('pages.xml.network')}</h2>
          <ul>
            <li>
              {data?.network && (
                <a
                  href={`http://localhost:3001${data.network.file_path.slice(
                    3,
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.network.name}
                </a>
              )}
            </li>
          </ul>
          <Form.Group className="xml__form__group">
            <Form.Control
              ref={networkInputRef}
              type="file"
              accept=".xml"
              onChange={(e) => handleFileChange(e, 'network')}
            />
            <Button variant="primary" onClick={handleSubmit}>
              {t('upload')}
            </Button>
          </Form.Group>
        </div>
        <h2>{t('pages.xml.agencies')}</h2>
        <ul>
          {data?.agencies &&
            data.agencies.map((agency) => (
              <li key={agency.id}>
                <a
                  href={`http://localhost:3001${agency.file_path.slice(3)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {agency.name}
                </a>
              </li>
            ))}
        </ul>
        <Form.Group className="xml__form__group">
          <Form.Control
            ref={agencyInputRef}
            type="file"
            accept=".xml"
            onChange={(e) => handleFileChange(e, 'agencies')}
          />
          <Button variant="primary" onClick={handleSubmit}>
            {t('upload')}
          </Button>
        </Form.Group>
      </Form>
      <Button variant="primary" onClick={handleGenerate}>
        {t('pages.xml.generate')}
      </Button>
      <ToastContainer position="bottom-end" className="xml__toast">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          bg={'success'}
          autohide
        >
          <Toast.Body>
            <span className="me-auto">{t('pages.xml.success')}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
