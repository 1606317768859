// import libraries
import React from 'react';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
// import css
import '../assets/styles/checkboxDropdown.scss';

export default function CheckboxDropdown({
  data,
  disabled,
  label,
  selected,
  setSelected,
  size,
  className,
}) {
  const selectChange = (event) => {
    const selectId = parseInt(event.target.value);
    const choosen = event.target.checked;

    if (choosen) {
      setSelected([...selected, selectId]);
    } else {
      setSelected(selected.filter((id) => id !== selectId));
    }
  };

  const handleSelectAll = () => {
    setSelected(data.map((option) => option.id));
  };

  const handleSelectNone = () => {
    setSelected([]);
  };

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        variant="none"
        id="dropdown-basic"
        disabled={disabled}
        size={size}
      >
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown__menu">
        {data.map((option) => (
          <Form.Check
            key={option.id}
            type="checkbox"
            id={option.id}
            label={option.value}
            checked={selected.includes(option.id)}
            onChange={selectChange}
            value={option.id}
          />
        ))}
        <Dropdown.Divider />
        <ButtonGroup size="sm" className="dropdown__menu__groupButton">
          <Button variant="link" onClick={handleSelectAll}>
            Select all
          </Button>
          <Button onClick={handleSelectNone}>Select none</Button>
        </ButtonGroup>
      </Dropdown.Menu>
    </Dropdown>
  );
}
