// import libraries
import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { getDashboard, getData } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
// import css
import '../../assets/styles/dashboard.scss';

/*
Dashboard page
WIP
Display only the first name of the logged-in user. The default page.
*/

function Dashboard() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // data receive of fetch
  const [data, setData] = useState([]);
  // display spinner during fetch
  const [isLoading, setLoading] = useState(false);
  const [isLog, setLog] = useState(false);
  // features
  const featureDashboardBroker = 'dashboard_broker';
  const featureDashboardAgency = 'dashboard_agency';
  const featureDashboardNetwork = 'dashboard_network';
  const featureDashboardAgencyOther = 'dashboard_broker_other_agency';
  const featureDashboardNetworkOther = 'dashboard_broker_other_network';
  const [auth, setAuth] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchAuthoriz = async () => {
      try {
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
    };
    fetchAuthoriz();
  }, []);

  // data fetch with user id
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        try {
          if (localStorage.getItem('token')) setLog(true);
        } catch (error) {
          console.log(error);
        }
        const response = await getDashboard(API_ROUTES.USERS.GET.DASHBOARD);
        setData(response.data);
        localStorage.setItem(
          'ids',
          JSON.stringify({
            user: response.data.user,
            id_agency: response.data.id_agency,
            agency: response.data.agency,
            country: response.data.country,
            locale: response.data.locale,
          }),
        );
      } catch (error) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login'); // redirect to login page if error
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <React.Fragment>
      {isLog && (
        <div className="d-flex flex-column align-items-center justify-content-center dashboard">
          <h1 className="mb-5">{t('pages.dashboard.title')}</h1>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <React.Fragment>
              <h2>
                {t('pages.dashboard.content')} {data.firstname}
              </h2>
              <select
                onChange={handleLanguageChange}
                defaultValue={i18n.language}
              >
                <option value="en">English</option>
                <option value="fr">Français</option>
              </select>
            </React.Fragment>
          )}
          <span>List of visible dashboard:</span>
          <ul>
            {auth.includes(featureDashboardBroker) && (
              <li>Your own dashboard</li>
            )}
            {auth.includes(featureDashboardAgency) && (
              <li>Agency's dashboard</li>
            )}
            {auth.includes(featureDashboardNetwork) && (
              <li>Network's dashboard</li>
            )}
            {auth.includes(featureDashboardAgencyOther) && (
              <li>Dashboard from an other commercial in the Agency</li>
            )}
            {auth.includes(featureDashboardNetworkOther) && (
              <li>Dashboard from an other commercial in the Network</li>
            )}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
}
// Export to call it up in app.jsx
export default Dashboard;
